import Cookies from "js-cookie";

export const mfaEnabled = (data: any) => {
  if (
    (data.otp_enabled && data.otp_verified) ||
    (data.otp_email_enabled && data.otp_email_verified)
  )
    return true;
  return false;
};

export const onlyAppMfaEnabled = (data: any) => {
  if (
    data.otp_enabled &&
    data.otp_verified &&
    (!data.otp_email_enabled || !data.otp_email_verified)
  )
    return true;
  return false;
};

export const onlyEmailMfaEnabled = (data: any) => {
  if (
    (!data.otp_enabled || !data.otp_verified) &&
    data.otp_email_enabled &&
    data.otp_email_verified
  )
    return true;
  return false;
};

export const appMfaEnabled = (data: any) => {
  if (data.otp_enabled && data.otp_verified) return true;
  return false;
};

export const emailMfaEnabled = (data: any) => {
  if (data.otp_email_enabled && data.otp_email_verified) return true;
  return false;
};

export const mfaLoggedIn = (data: any) => {
  return data.otp_validated && Cookies.get("otpValidated") === "true";
};

export const getUserDataFromContext = (data: any) => {
  return {
    company_banned: data.isBannedCompany,
    company_name: data.companyName,
    documents_verified: data.documents_verified,
    has_company: data.hasCompany,
    is_main_user: data.isMainUser,
    ...data,
  };
};
