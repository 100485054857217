import { useState, useEffect, useCallback, useContext } from "react";
import { usePost } from ".";
import AuthContext from "../context/AuthContext";
import { UserDetailsForm } from "../interface/Common";

export const useCreateUserKYC = () => {
  const authctx = useContext(AuthContext);

  const [data, callApi, isLoading, errMessage] = usePost(
    authctx.companyDetails.tokenAccess,
    true
  );
  const [response, setResponse] = useState("");

  const newUser = useCallback((formValues: UserDetailsForm | any) => {
    const formData = new FormData();
    for (const key of Object.keys(formValues)) {
      formData.append(key, formValues[key]);
    }
    callApi(formData, `auth/b2c-kyc-validation/`);
  }, []);

  useEffect(() => {
    if ("error" in data) {
      setResponse("error company");
    } else if ("kyc_documents_verified" in data) {
      setResponse("User KYC completed");
    }
  }, [data]);

  return [response, newUser, isLoading, errMessage] as const;
};
