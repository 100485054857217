import { useContext, useEffect, useMemo } from "react";
import { Navigate, Outlet } from "react-router-dom";
import AuthContext, {
  CompanyDetails,
  UserDetails,
} from "../../context/AuthContext";
import { Backdrop, CircularProgress } from "@mui/material";
import { useFetchQuery } from "../../customHooks/reactQuery/useFetchQuery";
import { Cookies } from "react-cookie-consent";

const styles = {
  paperContainer: {
    backgroundImage: `linear-gradient(120deg, #E6EAF5 0%, #F0E8EE 33%, #ECE9F4 61%, #E9ECF3 100%)`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    height: "100%",
    minHeight: "100vh",
  },
};

export default function UserSetUpRoute() {
  const ctxAuth = useContext(AuthContext);

  const { data, refetch, isError, isSuccess } = useFetchQuery(
    "token/validate-access-token/",
    "token-validation",
    false,
    false,
    Cookies.get("tokenAccess")
  );

  const centralDataHandler = (
    data: any,
    loginUser = true,
    otp_validated = false
  ) => {
    const backDetails: CompanyDetails = {
      companyName: data.company_name as string,
      tokenAccess: Cookies.get("tokenAccess") as string,
      tokenRefresh: Cookies.get("tokenRefresh") as string,
      referenceNumber: data.reference_number as string,
      company_kyc_form_signed: data.company_kyc_form_signed as boolean,
      isBannedCompany: data.company_banned as boolean,
      documentsVerified: data.documents_verified as boolean,
    };

    const userDetails: UserDetails = {
      email: data.email as string,
      name: data.name as string,
      kyc_information_added: data.kyc_information_added as boolean,
      isLoggedIn: loginUser,
      hasCompany: data.has_company as boolean,
      isMainUser: data.is_main_user as boolean,
      otp_enabled: data.otp_enabled as boolean,
      otp_verified: data.otp_verified as boolean,
      otp_validated: otp_validated,
      otp_email_enabled: data.otp_email_enabled as boolean,
      otp_email_verified: data.otp_email_verified as boolean,
      primary_2fa_method: data.primary_2fa_method as string,
      roles: data?.roles ? (data.roles as string[]) : [],
    };

    ctxAuth.setCompany(backDetails);
    ctxAuth.setUser(userDetails);
  };

  const login = (data: any) => {
    if (!data.company_kyc_form_signed) {
      //handle all KYC related stuff
      centralDataHandler(data);
    } else {
      // clearly the user is either in the dashboard or otp validation page
      // check if documents are verified

      if (data.documents_verified) {
        if (data.otp_verified || data.otp_email_verified) {
          if (data.otp_validated) {
            //going to the dashboard through otp validation
            centralDataHandler(data, true, true);
          } else {
            centralDataHandler(data, false, false);
          }
        } else {
          //going to the dashboard
          centralDataHandler(data, true, false);
        }
      }
    }
  };

  useEffect(() => {
    if (isSuccess) {
      if (data?.email.length > 0) {
        login(data);
      } else {
        ctxAuth.logout("/sign-in");
      }
    } else if (isError) {
      ctxAuth.logout("/sign-in");
    }
  }, [data, isError, isSuccess]);

  const PrivateCheck = useMemo(() => {
    if (!ctxAuth.userDetails.isLoggedIn) {
      // Move to otp validation page
      if (
        !ctxAuth.userDetails.otp_validated &&
        (ctxAuth.userDetails.otp_email_verified ||
          ctxAuth.userDetails.otp_verified)
      ) {
        return <Navigate to="/validate-otp" replace={true} />;
      }

      if (Cookies.get("tokenAccess")) {
        refetch();
      } else {
        ctxAuth.logout("/sign-in", false);
        return <Navigate to="/sign-in" />;
      }

      return (
        <Backdrop sx={styles.paperContainer} open={true}>
          <CircularProgress color="inherit" />
        </Backdrop>
      );
    } else {
      if (ctxAuth.userDetails.roles[0] === "client_company") {
        if (!ctxAuth.companyDetails.company_kyc_form_signed) {
          return <Navigate to="/company-setup" replace={true} />;
        } else {
          return <Navigate to="/dashboard" replace={true} />;
        }
      }

      if (ctxAuth.userDetails.kyc_information_added) {
        if (!ctxAuth.companyDetails.documentsVerified) {
          return <Navigate to="/verification" replace={true} />;
        }

        return <Navigate to="/dashboard" replace={true} />;
      }

      return <Outlet />;
    }
  }, [ctxAuth, Cookies.get("tokenAccess")]);

  return PrivateCheck;
}
