import React, { useEffect, useMemo, useState } from "react";
import secureLocalStorage from "react-secure-storage";
import { useNavigate } from "react-router-dom";
import { usePostUpdate } from "../customHooks/usePostUpdate";
import useIdleTimeout from "../customHooks/useIdleTimeout";
import IdleTimeoutDialog from "../compnents/dialogs/IdleTimeoutDialog";
import { Cookies } from "react-cookie-consent";
import { mfaEnabled, mfaLoggedIn } from "../utils/userFunctions";
import { useQueryClient } from "react-query";

type Props = {
  children: JSX.Element;
};

export type CompanyDetails = {
  companyName: string;
  tokenAccess: string;
  tokenRefresh: string;
  company_kyc_form_signed: boolean;
  referenceNumber: string;
  isBannedCompany: boolean;
  documentsVerified: boolean;
};

export type UserDetails = {
  email: string;
  name: string;
  kyc_information_added: boolean;
  isLoggedIn: boolean;
  hasCompany: boolean;
  isMainUser: boolean;
  otp_enabled: boolean;
  otp_verified: boolean;
  otp_validated: boolean;
  otp_email_enabled: boolean;
  otp_email_verified: boolean;
  primary_2fa_method: string;
  roles: string[];
};
export type Auth = {
  companyDetails: CompanyDetails;
  userDetails: UserDetails;
  loginHandler: (data: any, otp_validated?: boolean) => void;
  logout: (link?: string, isNavigate?: boolean) => void;
  setCompany: (state: CompanyDetails) => void;
  setUser: (state: UserDetails) => void;
  homeNavigation: () => void;
  navstatus: boolean;
  resetContext: () => void;
};
export const companyDetails: CompanyDetails = {
  companyName: "",
  tokenAccess: "",
  tokenRefresh: "",
  referenceNumber: "",
  company_kyc_form_signed: false,
  isBannedCompany: false,
  documentsVerified: false,
};

export const userDetails: UserDetails = {
  email: "",
  name: "",
  kyc_information_added: false,
  isLoggedIn: false,
  hasCompany: false,
  isMainUser: false,
  otp_enabled: false,
  otp_verified: false,
  otp_validated: false,
  otp_email_enabled: false,
  otp_email_verified: false,
  primary_2fa_method: "",
  roles: [],
};

const auth: Auth = {
  companyDetails,
  userDetails,
  loginHandler: (data: any, otp_validated?: boolean) => {},
  logout: (link?: string, isNavigate?: boolean) => {},
  setCompany: (state: CompanyDetails) => {},
  setUser: (state: UserDetails) => {},
  homeNavigation: () => {},
  navstatus: false,
  resetContext: () => {},
};

const AuthContext = React.createContext(auth);

export const AuthContextProvider = ({ children }: Props) => {
  const navigate = useNavigate();
  const [data2, callApi2, isLoading2] = usePostUpdate();
  const [navLink, setNavLink] = useState("");
  const query = useQueryClient();

  const [company, setCompany] = useState<CompanyDetails>(companyDetails);

  const [user, setUser] = useState<UserDetails>(userDetails);

  const logoutHandler = (link = "/", isNavigate = true) => {
    callApi2(
      { refresh_token: company.tokenRefresh },
      "auth/logout/",
      company.tokenAccess
    );

    setCompany(companyDetails);

    setUser(userDetails);

    secureLocalStorage.clear();
    Cookies.remove("tokenRefresh");
    Cookies.remove("tokenAccess");
    Cookies.remove("otpValidated");
    query.clear();
    if (isNavigate) navigate(link);
    idleTimer.pause();
  };

  const centralDataHandler = (
    data: any,
    nav: string,
    loginUser = true,
    otp_validated = false
  ) => {
    Cookies.set("tokenAccess", data.tokens?.access as string, {
      path: "/",
      expires: new Date(new Date().getTime() + 24 * 60 * 60 * 1000),
    });

    Cookies.set("tokenRefresh", data.token?.refresh as string, {
      path: "/",
      expires: new Date(new Date().getTime() + 24 * 60 * 60 * 1000),
    });

    Cookies.set("otpValidated", otp_validated.toString(), {
      path: "/",
      expires: new Date(new Date().getTime() + 24 * 60 * 60 * 1000),
    });

    // console.log("Auth ->", data.has_company);

    const backDetails: CompanyDetails = {
      companyName: data.company_name as string,
      tokenAccess: data.tokens.access,
      tokenRefresh: data.tokens.refresh,
      referenceNumber: data.reference_number as string,
      company_kyc_form_signed: data.company_kyc_form_signed as boolean,
      isBannedCompany: data.company_banned as boolean,
      documentsVerified: data.documents_verified as boolean,
    };

    const userDetails: UserDetails = {
      email: data.email as string,
      name: data.name as string,
      kyc_information_added: data.kyc_information_added as boolean,
      isLoggedIn: loginUser,
      hasCompany: data.has_company as boolean,
      isMainUser: data.is_main_user as boolean,
      otp_enabled: data.otp_enabled as boolean,
      otp_verified: data.otp_verified as boolean,
      otp_validated: otp_validated as boolean,
      otp_email_enabled: data.otp_email_enabled as boolean,
      otp_email_verified: data.otp_email_verified as boolean,
      primary_2fa_method: data.primary_2fa_method as string,
      roles: data?.roles ? (data.roles as string[]) : [],
    };

    setCompany(backDetails);
    setUser(userDetails);
    // navigate(nav);
    setNavLink(nav);
  };

  useEffect(() => {
    if (user.email && navLink.length > 0) {
      console.log("Navigating to", navLink);
      navigate(navLink);
      setNavLink("");
    }
  }, [navLink, user]);

  const login = (data: any, otp_validated?: boolean) => {
    if (data?.roles?.includes("client_company")) {
      if (data.has_company) {
        // check if company is banned
        if (data.company_banned) {
          return navigate("/company-access-verification");
        }

        // check if documents are verified
        if (data.documents_verified) {
          // check if main user
          if (mfaEnabled(data)) {
            centralDataHandler(
              data,
              otp_validated ? "/dashboard" : "/validate-otp",
              otp_validated ? otp_validated : false,
              otp_validated
            );
          } else if (data.is_main_user) {
            centralDataHandler(data, "/dashboard");
          } else {
            navigate("/main-user-verification");
          }
          //Check if company has been created but not signed
        } else if (!data.company_kyc_form_signed) {
          centralDataHandler(data, "/company-document");
        } else {
          navigate("/verification");
        }
        // }
      } else {
        //check if main user
        if (data.is_main_user) {
          centralDataHandler(data, "/company-setup");
        } else {
          navigate("/main-user-verification");
        }
      }
    } else if (data?.roles?.includes("client_individual")) {
      if (data.kyc_information_added) {
        if (!data.documents_verified) {
          navigate("/verification");
        } else {
          if (mfaEnabled(data)) {
            centralDataHandler(
              data,
              otp_validated ? "/dashboard" : "/validate-otp",
              otp_validated ? otp_validated : false,
              otp_validated
            );
          } else if (data.is_main_user) {
            centralDataHandler(data, "/dashboard");
          } else {
            navigate("/main-user-verification");
          }
        }
      } else {
        console.log("Going to user setup");
        centralDataHandler(data, "/user-setup");
      }
    }
  };

  const homeNavigation = () => {
    if (user.isLoggedIn && user?.roles?.includes("client_company")) {
      if (user.hasCompany) {
        if (company.documentsVerified) {
          navigate("/dashboard");
        } else if (!company.company_kyc_form_signed) {
          navigate("/company-document");
        } else {
          navigate("/verification");
        }
      } else {
        navigate("/company-setup");
      }
    } else if (user.isLoggedIn && user?.roles?.includes("client_individual")) {
      navigate("/dashboard");
    } else {
      navigate("/");
    }
  };

  const resetContext = () => {
    setCompany(companyDetails);
    setUser(userDetails);
  };

  // idle timeout
  const handleIdle = () => {
    setOpenModal(true);
  };
  const [openModal, setOpenModal] = useState(false);

  // navbar status
  const navstatus = useMemo(() => {
    if (mfaEnabled(user)) return mfaLoggedIn(user);
    // NOTE: not sure about how this logic affects the b2c navbar
    if (user?.roles?.includes("client_individual")) {
      //return user.isLoggedIn;
      return user.kyc_information_added && company.documentsVerified;
    }

    return user.isLoggedIn && user.hasCompany && company.documentsVerified;
  }, [user, company]);

  // logout user and pause timer
  const handleLogout = () => {
    logoutHandler();
    setOpenModal(false);
    idleTimer.pause();
  };

  // keep user logged in
  const stayLoggedIn = () => {
    setOpenModal(false);
    idleTimer.reset();
  };
  // call userIdleTimer Hook
  const { idleTimer } = useIdleTimeout({
    onIdle: handleIdle,
    // this converts to 900,000 milliseconds -> 15min
    idleTime: 60,
    // disabled: !logged,
    disabled: true,
    handleLogout: handleLogout,
  });

  // end of idletimeout

  return (
    <AuthContext.Provider
      value={{
        companyDetails: company,
        userDetails: user,
        loginHandler: login,
        logout: logoutHandler,
        setCompany: setCompany,
        setUser: setUser,
        homeNavigation: homeNavigation,
        navstatus: navstatus,
        resetContext: resetContext,
      }}
    >
      {children}
      {user.isLoggedIn && openModal && (
        <IdleTimeoutDialog
          stayLoggedIn={stayLoggedIn}
          logout={handleLogout}
          open={openModal}
          idleTimer={idleTimer}
        />
      )}
    </AuthContext.Provider>
  );
};

export default AuthContext;
