import { useContext, useEffect, useMemo, useState } from "react";
import { Navigate, Outlet, useNavigate } from "react-router-dom";
import AuthContext, {
  CompanyDetails,
  UserDetails,
} from "../../context/AuthContext";
import { Backdrop, CircularProgress } from "@mui/material";
import { useFetchQuery } from "../../customHooks/reactQuery/useFetchQuery";
import { Cookies } from "react-cookie-consent";
import { ClearUserDetails } from "./utils";
import {
  mfaEnabled,
  mfaLoggedIn,
  getUserDataFromContext,
} from "../../utils/userFunctions";

const styles = {
  paperContainer: {
    backgroundImage: `linear-gradient(120deg, #E6EAF5 0%, #F0E8EE 33%, #ECE9F4 61%, #E9ECF3 100%)`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    height: "100%",
    minHeight: "100vh",
  },
};

function PrivateRoutes() {
  const ctxAuth = useContext(AuthContext);
  const navigate = useNavigate();
  const [navLink, setNavLink] = useState<string>("");

  const { data, refetch, isError, isSuccess } = useFetchQuery(
    "token/validate-access-token/",
    "token-validation",
    false,
    false,
    Cookies.get("tokenAccess")
  );

  const centralDataHandler = (
    data: any,
    nav: string,
    loginUser = true,
    otp_validated = false
  ) => {
    const backDetails: CompanyDetails = {
      companyName: data.company_name as string,
      tokenAccess: Cookies.get("tokenAccess") as string,
      tokenRefresh: Cookies.get("tokenRefresh") as string,
      referenceNumber: data.reference_number as string,
      company_kyc_form_signed: data.company_kyc_form_signed as boolean,
      isBannedCompany: data.company_banned as boolean,
      documentsVerified: data.documents_verified as boolean,
    };

    const userDetails: UserDetails = {
      email: data.email as string,
      name: data.name as string,
      isLoggedIn: loginUser,
      kyc_information_added: data.kyc_information_added as boolean,
      hasCompany: data.has_company as boolean,
      isMainUser: data.is_main_user as boolean,
      otp_enabled: data.otp_enabled as boolean,
      otp_verified: data.otp_verified as boolean,
      otp_validated: otp_validated ? otp_validated : data.otp_validated,
      otp_email_enabled: data.otp_email_enabled as boolean,
      otp_email_verified: data.otp_email_verified as boolean,
      primary_2fa_method: data.primary_2fa_method as string,
      roles: data.roles ? (data.roles as string[]) : [],
    };

    ctxAuth.setCompany(backDetails);
    ctxAuth.setUser(userDetails);

    if (nav.length > 0) {
      setNavLink(nav);
    }
  };

  const login = (data: any) => {
    if (data?.roles?.includes("client_company")) {
      if (data.has_company) {
        // check if company is banned
        if (data.company_banned) {
          ClearUserDetails(ctxAuth);
          navigate("/company-access-verification");
        }

        // check if documents are verified
        if (data.documents_verified) {
          if (data.otp_enabled && !data.otp_verified) {
            return centralDataHandler(data, "/two-factor-app-setup");
          } else if (mfaEnabled(data) && !mfaLoggedIn(data)) {
            return centralDataHandler(data, "/validate-otp", false, false);
          }
          // // check if main user
          else if (data.is_main_user) {
            return centralDataHandler(data, "");
          } else {
            ClearUserDetails(ctxAuth);
            navigate("/main-user-verification");
          }
          //Check if company has been created but not signed
        } else if (!data.company_kyc_form_signed) {
          return centralDataHandler(data, "/company-document");
        } else {
          ClearUserDetails(ctxAuth);
          navigate("/verification");
        }
      } else {
        //check if main user
        if (data.is_main_user) {
          return centralDataHandler(data, "/company-setup");
        } else {
          ClearUserDetails(ctxAuth);
          navigate("/main-user-verification");
        }
      }
    } else if (data?.roles?.includes("client_individual")) {
      if (data.kyc_information_added) {
        if (!data.documents_verified) {
          navigate("/verification");
        } else {
          if (data.otp_enabled && !data.otp_verified) {
            return centralDataHandler(data, "/two-factor-app-setup");
          } else if (mfaEnabled(data) && !mfaLoggedIn(data)) {
            return centralDataHandler(data, "/validate-otp", false, false);
          } else if (data.is_main_user) {
            return centralDataHandler(data, "");
          } else {
            navigate("/main-user-verification");
          }
        }
      }else{
        navigate("/user-setup");
      }
    } else {
      ClearUserDetails(ctxAuth);
      navigate("/user-role-verification");
    }
  };

  useEffect(() => {
    if (isSuccess) {
      if (data?.email.length > 0) {
        if (mfaLoggedIn(ctxAuth.userDetails)) {
          const mfaData = getUserDataFromContext({
            ...ctxAuth.userDetails,
            ...ctxAuth.companyDetails,
          });
          login(mfaData);
        } else {
          login(data);
        }
      } else {
        ClearUserDetails(ctxAuth);
        navigate("/sign-in");
      }
    } else if (isError) {
      ClearUserDetails(ctxAuth);
      navigate("/sign-in");
    }
  }, [data, isError, isSuccess]);

  //Check if token is undefined
  useEffect(() => {
    if (ctxAuth.userDetails.isLoggedIn) {
      if (Cookies.get("tokenAccess") === undefined) {
        ClearUserDetails(ctxAuth);
      }
    }
  }, [Cookies.get("tokenAccess")]);

  const PrivateCheck = useMemo(() => {
    if (!ctxAuth.userDetails.isLoggedIn) {
      //Check if token is undefined and someone tries to access the page
      if (Cookies.get("tokenAccess") === undefined) {
        return <Navigate to="/sign-in" />;
      }

      if (
        mfaEnabled(ctxAuth.userDetails) &&
        !mfaLoggedIn(ctxAuth.userDetails)
      ) {
        return <Navigate to="/validate-otp" />;
      } else {
        refetch();
        return (
          <Backdrop sx={styles.paperContainer} open={true}>
            <CircularProgress color="inherit" />
          </Backdrop>
        );
      }
    } else {
      if (Cookies.get("tokenAccess") === undefined) {
        return (
          <Backdrop sx={styles.paperContainer} open={true}>
            <CircularProgress color="inherit" />
          </Backdrop>
        );
      }

      if (ctxAuth.userDetails.roles[0] === "client_individual") {
        console.log("Individual User", ctxAuth);

        if (!ctxAuth.userDetails.kyc_information_added) {
          console.log("User KYC not added");
          return <Navigate to="/user-setup" replace={true} />;
        }
      }

      if (navLink.length > 0) {
        return <Navigate to={navLink} />;
      }
      return <Outlet />;
    }
  }, [ctxAuth.userDetails.isLoggedIn, Cookies.get("tokenAccess"), navLink]);

  return PrivateCheck;
}

export default PrivateRoutes;
